<template>
<b-card no-body class="card-company-table">
    <table class="table b-table" v-if="questions">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Sıra</th>
                <th scope="col">Soru Resmi</th>
                <th scope="col">Çözüm Videosu</th>
                <th scope="col">Doğru Cevap</th>
                <th scope="col">Kazanım</th>
                <th scope="col">İşlemler</th>
            </tr>
        </thead>
        <draggable v-model="questions" tag="tbody" handle=".handle">
            <tr v-for="(item, index) in questions" :key="item.id">
                <td>
                    <feather-icon icon="MoveIcon" class="handle" />
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">#{{ index + 1 }}</span>
                </td>
                <td>
                    <img class="font-weight-bolder mb-12" style="max-height: 150px" :src="item.questionFile.url" />
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{
              item.solutionFile.url
            }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">
                        {{
                        item.answers.filter(x=>x.isTrue==true).length>0?
                        item.answers.filter((x) => x.isTrue == true)[0].text:'CEVAP SEÇİLMEDİ'
                }}
                    </span>
                </td>
                <td>
                    <feather-icon icon="BookIcon" @click="openGains(item)" />
                </td>
                <td>
                    <feather-icon icon="DeleteIcon" @click="deleteQuestion(item)" /><br />
                    <feather-icon icon="EditIcon" @click="editQuestion(item)" />
                </td>
            </tr>
        </draggable>
    </table>
    <!--Add Test Question --->
    <b-modal title="Soru Düzenle" ok-title="Düzenle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="editQuestionPanel" @ok="editTestQuestion">
        <b-form>
            <b-form-group label="Soru Resmi ?" label-for="name">
                <b-form-file v-model="editQuestionRequest.file" />
            </b-form-group>
            <b-form-group label="Çözüm Videosu ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="editQuestionRequest.solutionFile" />
            </b-form-group>
            <b-form-group label="Doğru Cevap ? " label-for="vue-select">
                <v-select id="vue-select" v-model="editQuestionRequest.isTrue" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(answers) => answers.key" label="key" :options="answers" />
            </b-form-group>
        </b-form>
    </b-modal>
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="50%" shadow backdrop right v-model="openGainsPanel">
        <template #footer>
            <div class="d-flex text-light align-items-center px-3 py-1">
                <strong class="mr-auto"></strong>
                <b-button variant="primary" class="mr-2" type="submit" @click="addGainsPanel=true">
                    Yeni Kazanım Ekle
                </b-button>
            </div>
        </template>
        <table class="table b-table" v-if="questionGains">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Kazanım Kodu</th>
                    <th scope="col">Kazanım Adı</th>
                    <th scope="col">İşlemler</th>
                </tr>
            </thead>
            <tr v-for="(item, index) in questionGains" :key="item.id">
                <td>
                    <span class="font-weight-bolder mb-12">#{{ index + 1 }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{ item.code }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{ item.text }}</span>
                </td>
                <td>
                    <feather-icon icon="Trash2Icon" @click="removeGains(item)" />
                </td>
            </tr>
        </table>
    </b-sidebar>
    <!--Add Test Question --->
    <b-modal title="Kazanım Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addGainsPanel" @ok="addQuestionGains">
        <b-form>
            <b-form-group label="Kazanım ?" label-for="vue-select">
                <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :reduce="(gains) => gains.id" :options="gains" v-model="addGainsRequest.gainId" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
} from "bootstrap-vue"
import vSelect from "vue-select"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import draggable from "vuedraggable"
export default {
    components: {
        ToastificationContent,
        draggable,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormFile,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
        vSelect,
    },
    props: ["testId", "event", "saveEvent"],
    data() {
        return {
            answers: [
                { key: "A" },
                { key: "B" },
                { key: "C" },
                { key: "D" },
                { key: "E" },
            ],
            gains: [],
            questions: null,
            editQuestionPanel: false,
            editQuestionRequest: {
                testId: 0,
                questionId: 0,
                questionFile: "",
                solutionFile: "",
                isTrue: "A",
            },
            selectedQuestion: {},
            openGainsPanel: false,
            questionGains: [],
            addGainsPanel: false,
            addGainsRequest: {
                testId: 0,
                questionId: 0,
                gainId: 0
            }
        }
    },
    watch: {
        event() {
            this.savePreview()
        },
        saveEvent() {
            this.getData()
        },
    },
    mounted() {
        this.getData()
        this.getGains()
    },
    methods: {
        async getGains() {
            var gains = await this.$http.get("Teacher/Gains/" + this.$route.params.videoGroupId);
            this.gains = gains.data.data;
        },
        async getData() {
            var questions = await this.$http.get(
                "Teacher/TestQuestion/" + this.testId
            )
            this.questions = questions.data.data
        },
        async savePreview() {
            var request = []
            var index = 1;
            this.questions.forEach((qr) => {
                request.push({
                    id: qr.id,
                    viewOrder: index,
                })
                index++
            })
            var users = await this.$http.put(
                "Teacher/UpdateTestQuestionView/" + this.testId,
                request
            )
            if (users.data.data == true) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "İşlem",
                        icon: "BellIcon",
                        text: "İşlem Başarılı Düzen Kaydedildi",
                        variant: "success",
                    },
                })
            }
        },
        editQuestion(question) {
            this.editQuestionRequest = {
                testId: this.testId,
                questionId: question.id,
                questionFile: question.questionFile.url,
                solutionFile: question.solutionFile.url,
                isTrue:null
            };
            if(question.answers.filter((x) => x.isTrue == true).length>0){
                this.editQuestionRequest.isTrue=question.answers.filter((x) => x.isTrue == true)[0].text;
            }
            this.editQuestionPanel = true
        },
        async openGains(question) {
            this.selectedQuestion = question;
            var gains = await this.$http.get("Teacher/TestQuestionGains/" + question.id);
            this.questionGains = gains.data.data;
            this.openGainsPanel = true;
        },
        async deleteQuestion(item) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.$http.delete("Teacher/DeleteTestQuestion/" + item.id);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "İşlem",
                                icon: "BellIcon",
                                text: "İşlem Başarılı Soru Kaldırıldı",
                                variant: "success",
                            },
                        });
                        this.getData();
                    }
                }
            });
        },
        async removeGains(item) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.$http.delete("Teacher/DeleteTestQuestionGains/" + this.selectedQuestion.id + "/" + item.id);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "İşlem",
                                icon: "BellIcon",
                                text: "İşlem Başarılı Kazanım Kaldırıldı",
                                variant: "success",
                            },
                        });
                        this.openGains(this.selectedQuestion);
                    }
                }
            });
        },
        async addQuestionGains() {
            var request = this.addGainsRequest;
            request.testId = this.testId;
            request.questionId = this.selectedQuestion.id;
            //console.log(request);
            this.$http.post("Teacher/AddTestQuestionGains", request).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "İşlem",
                        icon: "BellIcon",
                        text: "İşlem Başarılı Kazanım Eklendi",
                        variant: "success",
                    },
                });
                this.addGainsPanel = false;
                this.openGains(this.selectedQuestion);
            }).catch((error) => {
                //console.log(error.response.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Hata',
                        icon: 'BellIcon',
                        text: error.response.data.error.message,
                        variant: 'danger'
                    },
                });
            });
        },
        async editTestQuestion() {
            const file = this.editQuestionRequest.file;
            if(file != null){
                const request = new FormData();
                request.append("file",file);
                const fileResponse = await this.$http.put("Teacher/AddQuestionFile",request);
                this.editQuestionRequest.questionFile = fileResponse.data;
            }
            await this.$http.post(
                "Teacher/UpdateTestQuestion",
                this.editQuestionRequest
            )
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
